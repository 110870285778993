/* global $ */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import moment from 'moment';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

const CreateDialog = ({ selectedContract, consumer, showCreateContractDialog, onCreateContractDialogClosed, onContractCreated }) => {
  const [info, setInfo] = useState();
  const [spin, setSpin] = useState(true);
  const [showYesNoButtons, setShowYesNoButtons] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorDetails, setErrorDetails] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [savingMessage, setSavingMessage] = useState('Saving...');

  if (!selectedContract) {
    return null;
  }

  const onShow = async () => {
    setSpin(true);

    let info = {};

    try {
      const response = await axios.get(`/bonds/api/bonds/${selectedContract.id}`);
      info = {
        bondNumber: response.data.bondNumber,
        effectiveDate: response.data.effectiveDate,
        expirationDate: response.data.expirationDate,
        term: response.data.term,
        state: response.data.state,
        premium: response.data.premium,
        shipping: response.data.shipping,
        businessName: response.data.businessName,
        addressLine1: response.data.addressLine1,
        city: response.data.city,
        zip: response.data.zip,
        bondProvider: response.data.bondProvider,
        producer: response.data.producer,
        accountManager: response.data.accountManager,
        brokerAgreementConceptOneEntityID: response.data.brokerAgreementConceptOneEntityID,
        brokerConceptOneName: '',
      };
    } catch (error) {
      console.error(error);
    }

    if (info.brokerAgreementConceptOneEntityID) {
      try {
        const response = await axios.get(`/bonds/api/brokeragreements/${info.brokerAgreementConceptOneEntityID}`);
        info.brokerConceptOneName = response.data.brokerEntityName;
      } catch (error) {
        console.error(error);
      }
    }
    console.log('info: ', info);
    setInfo(info);
    setSpin(false);
    setShowYesNoButtons(true);
  };

  const onCreateConfirm = async () => {
    setShowYesNoButtons(false);
    setSpin(true);

    const instance = uuidv4();

    const connection = $.hubConnection('/bonds/signalr', { useDefaultPath: false });
    const proxy = connection.createHubProxy('genericMessageHub');
    proxy.on('message', (messageInstance, message) => {
      if (messageInstance == instance) {
        setSavingMessage(message);
      }
    });
    await connection.start();

    try {
      const response = await axios.post('/bonds/api/contracts', {
        bondID: selectedContract.id,
        connectionId: connection.id,
        instance: instance,
      });

      setShowCloseButton(true);

      if (!response.data.wasSuccess) {
        setErrorMessage(response.data.errorMessage);
        setErrorDetails(response.data.errorDetails);
        setShowError(true);
      } else {
        setShowSuccess(true);
      }

      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onCreateSuccessClosed = async () => {
    onContractCreated();
    onCreateContractDialogClosed();
  };

  let footer = null;
  if (showYesNoButtons) {
    footer = (
      <div>
        <Button label="Yes" icon="pi pi-check" onClick={onCreateConfirm} type="button" />
        <Button label="No" icon="pi pi-times" onClick={onCreateContractDialogClosed} type="button" />
      </div>
    );
  } else if (showCloseButton) {
    footer = (
      <div>
        <Button label="Close" icon="pi pi-times" onClick={onCreateSuccessClosed} type="button" />
      </div>
    );
  }

  return (
    <Dialog
      header="Create Contract"
      visible={showCreateContractDialog}
      modal={true}
      onHide={onCreateContractDialogClosed}
      onShow={onShow}
      style={{ width: '50vw' }}
      footer={footer}
    >
      {!spin || (
        <center>
          <ProgressSpinner />
          <br />
          {savingMessage}...
        </center>
      )}
      {spin || showSuccess || showError || (
        <>
          <p>Are you sure you want to create this contract in ConceptOne?</p>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Entity Name</label>
              <div className="p-col">{consumer.conceptOneConsumerName}</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Policy Number</label>
              <div className="p-col">{info.bondNumber}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Effective Date</label>
              <div className="p-col">{moment(info.effectiveDate).format('l')}</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Expiration Date</label>
              <div className="p-col">{moment(info.expirationDate).format('l')}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Term</label>
              <div className="p-col">{info.term}</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Contract State</label>
              <div className="p-col">{info.state}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Program</label>
              <div className="p-col">Home Medical Equipment</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Line Code</label>
              <div className="p-col">BONDS</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Division</label>
              <div className="p-col">100 - VGMI</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Department</label>
              <div className="p-col">Home Medical Equipment</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Bill To</label>
              <div className="p-col">Consumer</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Receivable</label>
              <div className="p-col">Consumer</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Premium</label>
              <div className="p-col">{info.premium.toFixed(2)}</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Shipping</label>
              <div className="p-col">{info.shipping.toFixed(2)}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Source Supplier</label>
              <div className="p-col">Old Hickory Insurance Company</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Risk Supplier</label>
              <div className="p-col">{info.bondProvider}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Retro Type</label>
              <div className="p-col">Inception</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Inception Date</label>
              <div className="p-col">{moment(info.effectiveDate).format('l')}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Market Type</label>
              <div className="p-col">Brokerage</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Settlement</label>
              <div className="p-col">Claims Made</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed p-col-align-start width-150-px">Contract Location</label>
              <div className="p-col">
                {info.businessName}
                <br />
                {info.addressLine1}
                <br />
                {info.city}, {info.state} {info.zip}
              </div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed p-col-align-start width-150-px">Retail Broker</label>
              <div className="p-col">{info.brokerConceptOneName ? info.brokerConceptOneName : ''}</div>
            </div>
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Producer</label>
              <div className="p-col">{info.producer}</div>
            </div>
            <div className="p-field p-grid p-col">
              <label className="p-col-fixed width-150-px">Account Manager</label>
              <div className="p-col">{info.accountManager}</div>
            </div>
          </div>
        </>
      )}
      {!showSuccess || (
        <div>
          <center>
            <i className="pi pi-check-circle" style={{ fontSize: '5em', color: 'green' }}></i>
            <br />
            Contract created successfully!
          </center>
        </div>
      )}
      {!showError || (
        <div>
          <center>
            <i className="pi pi-exclamation-circle" style={{ fontSize: '5em', color: 'red' }}></i>
            <br />
            <p>
              <strong>Error when creating contract</strong>
            </p>
            <p>Message: {errorMessage || 'ConceptOne error'}</p>
            {!errorDetails || (
              <p>
                <textarea rows={10} cols={100} readOnly defaultValue={errorDetails} />
              </p>
            )}
          </center>
        </div>
      )}
    </Dialog>
  );
};

CreateDialog.propTypes = {
  selectedContract: PropTypes.object,
  consumer: PropTypes.object,
  showCreateContractDialog: PropTypes.bool.isRequired,
  onCreateContractDialogClosed: PropTypes.func.isRequired,
  onContractCreated: PropTypes.func.isRequired,
};

export default CreateDialog;
