import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import Spinner from '../integrations/dialog/Spinner';
import SignatureUpload from './components/SignatureUpload.jsx';
import AddSignatureDialog from './components/AddSignatureDialog.jsx';
import ActionButtons from './components/ActionButtons.jsx';

import './SignaturesDialog.scss';

function SignaturesDialog({ showSignaturesDialog, onClosed }) {
  const [showSpinner, setShowSpinner] = useState(true);
  const [signatures, setSignatures] = useState([]);
  const [editingRow, setEditingRow] = useState();
  const [showAddSignatureDialog, setShowAddSignatureDialog] = useState(false);

  useEffect(() => {
    loadSignatures();
  }, [showSignaturesDialog]);

  const loadSignatures = async () => {
    setShowSpinner(true);

    let signatureResults = [];
    const signatureResponse = await fetch('/bonds/api/reporting/signature');
    if (signatureResponse.ok) {
      const results = await signatureResponse.json();
      signatureResults = results;
    } else {
      throw signatureResponse;
    }

    let defaultSignature;
    const defaultSignatureResponse = await fetch('/bonds/api/reporting/signature/default');
    if (defaultSignatureResponse.ok) {
      defaultSignature = await defaultSignatureResponse.json();
    } else {
      throw defaultSignatureResponse;
    }

    setSignatures([
      {
        userName: 'Default',
        signatureBase64: defaultSignature.signatureBase64,
        contentType: defaultSignature.contentType,
        userID: undefined,
      },
      ...signatureResults,
    ]);

    setShowSpinner(false);
  };

  const signatureTemplate = (rowData) => {
    if (rowData === editingRow) {
      return (
        <SignatureUpload
          onComplete={async () => {
            setEditingRow();
            await loadSignatures();
          }}
          userID={rowData.userID}
        />
      );
    }

    const url = `data:${rowData.contentType};base64,${rowData.signatureBase64}`;
    return <img src={url} style={{ border: '1px solid' }} />;
  };

  const getRowClass = (data) => {
    return {
      'row-highlight': data.userName.toLowerCase() === 'default',
    };
  };

  return (
    <Dialog header="Signatures" visible={showSignaturesDialog} onHide={onClosed} modal={true} style={{ width: '50vw', maxHeight: '750px' }}>
      {showSpinner ? (
        <Spinner message="Loading..." />
      ) : (
        <>
          <h1>Signatures</h1>
          <div>
            <div className="card">
              <DataTable value={signatures} rowClassName={getRowClass}>
                <Column field="userName" header="UserName"></Column>
                <Column field="email" header="Email"></Column>
                <Column field="signatureBlob" header="Signature" body={signatureTemplate}></Column>
                <Column
                  body={(rowData) => <ActionButtons rowData={rowData} editingRow={editingRow} onSetEditingRow={setEditingRow} onCancel={async () => await loadSignatures()} />}
                  style={{ width: '150px' }}
                ></Column>
              </DataTable>
            </div>
            <Button type="button" label="Add" onClick={() => setShowAddSignatureDialog(true)} style={{ width: '75px', marginTop: '2px' }} />
          </div>
          <AddSignatureDialog
            showAddSignatureDialog={showAddSignatureDialog}
            onClosed={async () => {
              setShowAddSignatureDialog(false);
              await loadSignatures();
            }}
            existingSignatureUserIds={signatures.map((s) => s.userID)}
          />
        </>
      )}
    </Dialog>
  );
}

SignaturesDialog.propTypes = {
  showSignaturesDialog: PropTypes.bool.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default SignaturesDialog;
