import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Message } from 'primereact/message';

import ConfirmDialog from '../common/ConfirmDialog';
import SmallSpinner from '../common/SmallSpinner';
import ChooseDialog from './consumer/ChooseDialog.jsx';
import CreateDialog from './consumer/CreateDialog.jsx';

const Consumer = ({ loading, consumer, onLinkConsumer, onConsumerCreated }) => {
  const [showChooseConsumerDialog, setShowChooseConsumerDialog] = useState(false);
  const [showCreateConsumerDialog, setShowCreateConsumerDialog] = useState(false);
  const [showChangeWarning, setShowChangeWarning] = useState(false);

  const onLinkClicked = async (e) => {
    e.preventDefault();
    setShowChooseConsumerDialog(true);
  };

  let result = '';

  if (consumer) {
    const changeButton = (
      <>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowChangeWarning(true);
          }}
        >
          [Change Consumer]
        </a>
        <ConfirmDialog
          header="Are you sure?"
          message="If you change the Consumer, you will need to re-map all of the bonds. Are you sure you want to do this?"
          show={showChangeWarning}
          onHide={() => setShowChangeWarning(false)}
          onYes={() => {
            setShowChangeWarning(false);
            setShowChooseConsumerDialog(true);
          }}
        />
      </>
    );
    if (consumer.conceptOneConsumerFound) {
      result = (
        <>
          <div data-concept-one-id={consumer.conceptOneConsumerID}>
            <Message severity="success" text={`Consumer linked to ConceptOne: ${consumer.conceptOneConsumerName}`} />
          </div>
          <div>{changeButton}</div>
        </>
      );
    } else {
      result = (
        <>
          <Message severity="error" text={`An invalid ConsumerID was linked: ${consumer.conceptOneConsumerID}`} /> {changeButton}
        </>
      );
    }
  } else {
    result = (
      <a href="#" onClick={onLinkClicked}>
        <Message severity="warn" text="Consumer not linked. Click here to link." />
      </a>
    );
  }

  return (
    <div>
      {loading ? <SmallSpinner text="Loading consumer data..." /> : result}
      <ChooseDialog
        showChooseConsumerDialog={showChooseConsumerDialog}
        onChooseConsumerDialogClosed={() => setShowChooseConsumerDialog(false)}
        onLinkConsumer={onLinkConsumer}
        onCreateConsumerClicked={() => setShowCreateConsumerDialog(true)}
      />
      <CreateDialog
        showCreateConsumerDialog={showCreateConsumerDialog}
        onCreateConsumerDialogClosed={() => setShowCreateConsumerDialog(false)}
        onConsumerCreated={onConsumerCreated}
      />
    </div>
  );
};

Consumer.propTypes = {
  loading: PropTypes.bool,
  consumer: PropTypes.shape({
    conceptOneConsumerID: PropTypes.string,
    conceptOneConsumerFound: PropTypes.bool,
    conceptOneConsumerName: PropTypes.string,
  }),
  onLinkConsumer: PropTypes.func,
  onConsumerCreated: PropTypes.func,
};

export default Consumer;
