/* global $ */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

const RenewDialog = ({ selectedContract, onRenewContractDialogClosed, showRenewContractDialog, consumer, onContractRenewed }) => {
  const [spin, setSpin] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showYesNoButtons, setShowYesNoButtons] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [errorDetails, setErrorDetails] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [savingMessage, setSavingMessage] = useState('Saving...');

  if (!selectedContract) {
    return null;
  }

  const onRenewConfirm = async () => {
    setShowYesNoButtons(false);
    setSpin(true);

    const instance = uuidv4();

    const connection = $.hubConnection('/bonds/signalr', { useDefaultPath: false });
    const proxy = connection.createHubProxy('genericMessageHub');
    proxy.on('message', (messageInstance, message) => {
      if (messageInstance == instance) {
        setSavingMessage(message);
      }
    });
    await connection.start();

    try {
      const response = await axios.post(`/bonds/api/contracts/${selectedContract.previousContract.contractID}/renew`, {
        renewalID: selectedContract.renewalID,
        connectionId: connection.id,
        instance: instance,
      });
      setShowCloseButton(true);
      if (!response.data.wasSuccess) {
        setErrorMessage(response.data.errorMessage);
        setErrorDetails(response.data.errorDetails);
        setShowError(true);
      } else {
        setShowSuccess(true);
      }

      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onRenewSuccessClosed = async () => {
    onContractRenewed();
    onRenewContractDialogClosed();
  };

  let footer = null;
  if (showYesNoButtons) {
    footer = (
      <div>
        <Button label="Yes" icon="pi pi-check" onClick={onRenewConfirm} type="button" />
        <Button label="No" icon="pi pi-times" onClick={onRenewContractDialogClosed} type="button" />
      </div>
    );
  } else if (showCloseButton) {
    footer = (
      <div>
        <Button label="Close" icon="pi pi-times" onClick={onRenewSuccessClosed} type="button" />
      </div>
    );
  }

  return (
    <Dialog header="Renew Contract" visible={showRenewContractDialog} modal={true} onHide={onRenewContractDialogClosed} style={{ width: '50vw' }} footer={footer}>
      {!spin || (
        <center>
          <ProgressSpinner />
          <br />
          {savingMessage}...
        </center>
      )}
      {spin || showSuccess || showError || (
        <>
          <p>Are you sure you want to renew this contract in ConceptOne?</p>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '150px' }}>
              Entity Name:
            </label>
            <div className="p-col">{consumer.conceptOneConsumerName}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '150px' }}>
              Renewing:
            </label>
            <div className="p-col">
              Policy {selectedContract.bondNumber}, Effective {selectedContract.previousContract ? selectedContract.previousContract.term : ''}
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '150px' }}>
              Term:
            </label>
            <div className="p-col">{selectedContract.term}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '150px' }}>
              Months:
            </label>
            <div className="p-col">{selectedContract.termMonths}</div>
          </div>
        </>
      )}
      {!showSuccess || (
        <div>
          <center>
            <i className="pi pi-check-circle" style={{ fontSize: '5em', color: 'green' }}></i>
            <br />
            Contract renewed successfully!
          </center>
        </div>
      )}
      {!showError || (
        <div>
          <center>
            <i className="pi pi-exclamation-circle" style={{ fontSize: '5em', color: 'red' }}></i>
            <br />
            <p>
              <strong>Error when renewing contract</strong>
            </p>
            <p>Message: {errorMessage || 'ConceptOne error'}</p>
            {!errorDetails || (
              <p>
                <textarea rows={10} cols={100} readOnly defaultValue={errorDetails} />
              </p>
            )}
          </center>
        </div>
      )}
    </Dialog>
  );
};

RenewDialog.propTypes = {
  selectedContract: PropTypes.object,
  consumer: PropTypes.object,
  showRenewContractDialog: PropTypes.bool.isRequired,
  onRenewContractDialogClosed: PropTypes.func.isRequired,
  onContractRenewed: PropTypes.func.isRequired,
};

export default RenewDialog;
