import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';
import { Messages } from 'primereact/messages';
import { TabMenu } from 'primereact/tabmenu';

import useRefreshableFetch from '../../../services/useRefreshableFetch';
import './ManageBondNumbersDialog.scss';

const FormStatus = {
  Idle: 'Idle',
  Submitting: 'Submitting',
  Submitted: 'Submitted',
  Completed: 'Completed',
};

function ManageBondNumbersDialog({ show, onClosed }) {
  const tabs = [{ label: 'Lexon' }, { label: 'Bond Safeguard' }];
  const bondNumberPrefixes = { 1: 'LICX', 2: 'BSIC' };
  const intialValidation = { startRange: '', endRange: '' };
  const initialInput = { prefix: bondNumberPrefixes['1'], startRange: undefined, endRange: undefined };

  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [addInput, setAddInput] = useState(initialInput);
  const [validation, setValidation] = useState(intialValidation);
  const [formStatus, setFormStatus] = useState(FormStatus.Idle);
  const [dataAsOf, setDataAsOf] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedBondType, setSelectedBondType] = useState(1);

  const notifications = useRef(null);

  const { data: numbers, loading, error, dataAsOf: asOf } = useRefreshableFetch(`/bonds/api/bondnumbers?bondtype=${selectedBondType}`, dataAsOf);

  function handleAddInputChange(value, field) {
    setAddInput((prevState) => ({ ...prevState, [field]: value }));
  }

  function onDeleteBondNumbersClicked(e) {
    console.log('selectedNumbers: ', JSON.stringify(selectedNumbers.map((sn) => sn.number)));
    confirmPopup({
      target: e.currentTarget,
      message: `Are you sure you want to delete these ${selectedNumbers.length} bond numbers?`,
      icon: 'pi pi-exclamation-triangle',
      accept: async function () {
        setIsDeleting(true);

        const response = await fetch(`/bonds/api/bondnumbers`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ bondType: selectedBondType, bondNumbers: selectedNumbers.map((sn) => sn.number) }),
        });
        if (response.ok) {
          setSelectedNumbers([]);
          setIsDeleting(false);
          notifications.current.show({ severity: 'success', summary: 'Bond numbers deleted!', life: 5000 });
          setDataAsOf(new Date());
        } else {
          setIsDeleting(false);

          (await response.json()).forEach((msg) => {
            notifications.current.show({ severity: 'error', summary: msg, life: 5000 });
          });

          throw response;
        }
      },
    });
  }

  function onAddBondNumbersClicked(e) {
    setValidation(intialValidation);

    let isValid = true;

    if (!addInput.startRange) {
      setValidation((prevState) => ({ ...prevState, startRange: 'Required' }));
      isValid = false;
    }

    if (!addInput.endRange) {
      setValidation((prevState) => ({ ...prevState, endRange: 'Required' }));
      isValid = false;
    }

    if (parseInt(addInput.endRange) < parseInt(addInput.startRange)) {
      setValidation((prevState) => ({ ...prevState, endRange: 'Cannot be less than start range' }));
      isValid = false;
    }

    if (isValid) {
      confirmPopup({
        target: e.currentTarget,
        message: `Are you sure you want to add bond numbers ${addInput.prefix}${addInput.startRange} - ${addInput.prefix}${addInput.endRange}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: async function () {
          setFormStatus(FormStatus.Submitting);

          const bondNumbers = [];
          for (let index = addInput.startRange; index <= addInput.endRange; index++) {
            bondNumbers.push(`${addInput.prefix}${index}`);
          }

          const response = await fetch(`/bonds/api/bondnumbers`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ bondType: selectedBondType, bondNumbers: bondNumbers }),
          });
          if (response.ok) {
            setAddInput(initialInput);
            setFormStatus(FormStatus.Submitted);
            notifications.current.show({ severity: 'success', summary: 'Bond numbers saved!', life: 5000 });
            setDataAsOf(new Date());
          } else {
            setFormStatus(FormStatus.Submitted);

            (await response.json()).forEach((msg) => {
              notifications.current.show({ severity: 'error', summary: msg, life: 5000 });
            });

            throw response;
          }
        },
      });
    } else {
      setFormStatus(FormStatus.Submitted);
    }
  }

  function tabChanged(e) {
    const bondType = e.index + 1; //The tabs are ordered the same as server side enum
    setActiveTab(e.index);
    setAddInput((prevState) => ({ ...prevState, prefix: bondNumberPrefixes[bondType.toString()] }));
    setSelectedBondType(bondType);
    setDataAsOf(new Date());
  }

  function renderTableHeader() {
    return (
      <>
        <div className="header-tabs">
          <TabMenu model={tabs} activeIndex={activeTab} onTabChange={tabChanged} />
        </div>
        <div className="p-fluid p-formgrid p-grid card">
          <div className="p-field p-col">
            <label htmlFor="prefix">Prefix</label>
            <InputText id="prefix" type="text" value={addInput.prefix} onChange={(e) => handleAddInputChange(e.target.value, 'prefix')} />
          </div>
          <div className="p-field p-col">
            <label htmlFor="startRange">Start Range</label>
            <InputNumber
              inputId="startRange"
              value={addInput.startRange}
              onValueChange={(e) => handleAddInputChange(e.value, 'startRange')}
              useGrouping={false}
              className={validation.startRange ? 'p-invalid' : ''}
            />
            <small className="p-error p-d-block">{validation.startRange}</small>
          </div>
          <div className="p-field p-col">
            <label htmlFor="endRange">End Range</label>
            <InputNumber
              inputId="endRange"
              value={addInput.endRange}
              onValueChange={(e) => handleAddInputChange(e.value, 'endRange')}
              useGrouping={false}
              className={validation.endRange ? 'p-invalid' : ''}
            />
            <small className="p-error p-d-block">{validation.endRange}</small>
          </div>
          <div className="p-field p-col">
            <label>&nbsp;</label>
            <Button id="submit" label="Add Bond Numbers" onClick={onAddBondNumbersClicked} loading={formStatus === FormStatus.Submitting} />
          </div>
        </div>
        <Messages ref={notifications} />
      </>
    );
  }

  function renderDialogFooter() {
    let count = <></>;
    if (selectedNumbers.length > 0) {
      count = <Button label={`Delete ${selectedNumbers.length} numbers`} className="p-button-danger left" loading={isDeleting} onClick={onDeleteBondNumbersClicked} />;
    } else if (numbers) {
      count = <div>Count: {numbers?.length} available bond numbers</div>;
    }

    return (
      <div className="p-d-flex p-jc-between">
        <div>{count}</div>
        <div className="p-as-end">{asOf && <>Data as of {moment(asOf).format('lll')}</>}</div>
      </div>
    );
  }

  const dateTemplate = (rowData) => {
    return moment(rowData.createdOn).format('lll');
  };

  const tableHeader = renderTableHeader();
  const dialogFooter = renderDialogFooter();

  if (error) throw error;

  return (
    <Dialog
      header="Available Bond Numbers"
      className="manage-bond-numbers-dialog"
      footer={dialogFooter}
      visible={show}
      onHide={onClosed}
      modal={true}
      style={{ width: '50vw', maxHeight: '750px' }}
      onShow={() => setDataAsOf(new Date())}
    >
      <DataTable header={tableHeader} value={numbers} selection={selectedNumbers} onSelectionChange={(e) => setSelectedNumbers(e.value)} loading={loading}>
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
        <Column field="number" header="Number"></Column>
        <Column field="createdOn" header="Added On" body={dateTemplate}></Column>
      </DataTable>
    </Dialog>
  );
}

ManageBondNumbersDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClosed: PropTypes.func.isRequired,
};

export default ManageBondNumbersDialog;
