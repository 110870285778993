import React, { useState } from 'react';

import SignaturesDialog from './SignaturesDialog.jsx';

export default function Reporting() {
  const [showSignaturesDialog, setShowSignaturesDialog] = useState(false);

  return (
    <>
      <h2>Reporting</h2>
      <a href="#" onClick={() => setShowSignaturesDialog(true)}>
        Signatures
      </a>
      <SignaturesDialog showSignaturesDialog={showSignaturesDialog} onClosed={() => setShowSignaturesDialog(false)} />
    </>
  );
}
