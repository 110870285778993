import React from 'react';
import { Table } from 'evergreen-ui';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import DiffButton from './DiffButton';

class AuditList extends React.Component {
  render() {
    return (
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Operation</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
            Diff
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height={480}>
          {this.props.auditEvents.map(evt => (
            <Table.Row key={evt.id}>
              <Table.TextCell>
                <Moment format="MM-DD-YYYY hh:mm:ss A">{evt.eventDate}</Moment>
              </Table.TextCell>
              <Table.TextCell>{evt.operation}</Table.TextCell>
              <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                <DiffButton onClick={this.props.onShowDiff} event={evt} />
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

AuditList.propTypes = {
  auditEvents: PropTypes.array.isRequired,
  onShowDiff: PropTypes.func.isRequired,
};

export default AuditList;
