/* global $ */
import React, { useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';

export default function Notifications() {
  useEffect(() => {
    async function connect() {
      const connection = $.hubConnection('/bonds/signalr', { useDefaultPath: false });
      const proxy = connection.createHubProxy('notificationHub');
      proxy.on('message', (severity, message) => {
        let toastSeverity;
        let summary;
        switch (severity) {
          case 'error':
            toastSeverity = 'error';
            summary = 'Error';
            break;
          case 'warning':
            toastSeverity = 'warn';
            summary = 'Warning';
            break;
          case 'success':
            toastSeverity = 'success';
            summary = 'Success';
            break;
          default:
            toastSeverity = 'info';
            summary = 'Info';
            break;
        }

        toast.current.show({ severity: toastSeverity, summary: summary, detail: message, life: 10000 });
      });
      await connection.start();
    }

    connect();
  });

  const toast = useRef(null);

  return <Toast ref={toast} position="bottom-right" />;
}
