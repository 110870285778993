import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Consumer from './Consumer.jsx';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './Integrations.scss';

const urlParams = new URLSearchParams(window.location.search);

const IntegrationsDataEntry = () => {
  const [loadingConsumer, setLoadingConsumer] = useState(true);
  const [consumer, setConsumer] = useState();

  useEffect(() => {
    async function load() {
      await loadData();
    }
    load();
  }, []);

  const loadData = async () => {
    setLoadingConsumer(true);

    try {
      const response = await axios.get(`/bonds/api/applications/${urlParams.get('applicationid')}/consumer`);

      setConsumer(response.data);
      setLoadingConsumer(false);
    } catch (error) {
      if (error.response.status === 404) {
        setLoadingConsumer(false);
      } else {
        console.error(error);
      }
    }
  };

  const onLinkConsumer = async (conceptOneConsumerId) => {
    setLoadingConsumer(true);

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.post(`/bonds/api/applications/${urlParams.get('applicationid')}/consumers`, {
        ConceptOneConsumerID: conceptOneConsumerId,
      });

      setConsumer(response.data);
      setLoadingConsumer(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h2>ConceptOne Integration</h2>
      <Consumer loading={loadingConsumer} consumer={consumer} onLinkConsumer={onLinkConsumer} onConsumerCreated={(consumer) => setConsumer(consumer)} />
    </>
  );
};

export default IntegrationsDataEntry;
