import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmPopup } from 'primereact/confirmpopup';

import './ButtonMenu.scss';

const ButtonMenu = ({ targetElementId, isVisible, onHide, children }) => {
  return (
    <ConfirmPopup
      className="button-menu"
      target={document.getElementById(targetElementId)}
      visible={isVisible}
      onHide={onHide}
      message={children}
      icon="pi pi-exclamation-triangle"
    />
  );
};

ButtonMenu.propTypes = {
  targetElementId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ButtonMenu;
