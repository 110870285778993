import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';

import Consumer from './Consumer.jsx';
import Contracts from './Contracts.jsx';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './Integrations.scss';

export default function Integrations() {
  const refMessages = useRef();
  const [spin, setSpin] = useState(true);
  const [showData, setShowData] = useState(false);
  const [bonds, setBonds] = useState([]);
  const [consumer, setConsumer] = useState();
  const [loadingConsumer, setLoadingConsumer] = useState(false);

  useEffect(() => {
    async function load() {
      await loadBonds();
    }
    load();
  }, []);

  async function loadBonds() {
    setSpin(true);
    let bondsResponse;

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.get(`/bonds/api/applications/${urlParams.get('applicationid')}/bonds`);
      console.log('response:', response);
      bondsResponse = response.data;
      setBonds(bondsResponse);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }

    if (bondsResponse.length === 0) {
      refMessages.current.show({
        severity: 'warn',
        sticky: true,
        closable: false,
        detail: 'Bond must be issued before integrations are enabled.',
      });
    } else {
      setShowData(true);
      await loadConsumer();
    }
  }

  async function loadConsumer() {
    setLoadingConsumer(true);
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.get(`/bonds/api/applications/${urlParams.get('applicationid')}/consumer`);
      console.log('response:', response);
      setConsumer(response.data);
      setLoadingConsumer(false);
    } catch (error) {
      if (error.response.status === 404) {
        setLoadingConsumer(false);
      } else {
        console.error(error);
      }
    }
  }

  async function onLinkConsumer(conceptOneConsumerId) {
    setLoadingConsumer(true);

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.post(`/bonds/api/applications/${urlParams.get('applicationid')}/consumers`, {
        ConceptOneConsumerID: conceptOneConsumerId,
      });
      console.log('response:', response);
      setConsumer(response.data);
      setLoadingConsumer(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function onLinkContract(bondID, renewalID, conceptOneContractID) {
    setSpin(true);
    setShowData(false);

    try {
      const response = await axios.post(`/bonds/api/bonds/${bondID}/contracts`, {
        ConceptOneContractID: conceptOneContractID,
        RenewalID: renewalID,
      });
      console.log('response:', response);
    } catch (error) {
      console.error(error);
    }

    await loadBonds();
  }

  async function reloadContract() {
    setSpin(true);
    setShowData(false);

    await loadBonds();
  }

  return (
    <div>
      {!spin || (
        <center>
          <ProgressSpinner />
        </center>
      )}
      <Messages ref={refMessages} />
      {!showData || (
        <>
          <div className="divider"></div>
          <h3>Consumer</h3>
          <div className="p-field p-grid">
            <label className="p-col-fixed width-125-px"></label>
            <div className="p-col">
              <Consumer loading={loadingConsumer} consumer={consumer} onLinkConsumer={onLinkConsumer} onConsumerCreated={(consumer) => setConsumer(consumer)} />
            </div>
          </div>

          {!consumer || (
            <>
              <div className="divider"></div>
              <Contracts
                bonds={bonds}
                consumer={consumer}
                onContractCreated={reloadContract}
                onLinkContract={onLinkContract}
                onContractCancelled={reloadContract}
                onErrorIgnored={reloadContract}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
