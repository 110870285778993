import React from 'react';
import { IconButton, majorScale } from 'evergreen-ui';
import PropTypes from 'prop-types';

class DiffButton extends React.Component {
  click = () => {
    this.props.onClick(this.props.event);
  };

  render() {
    return <IconButton icon="changes" height={majorScale(3)} onClick={this.click} />;
  }
}

DiffButton.propTypes = {
  event: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DiffButton;
