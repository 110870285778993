import React from 'react';
import { Pane, Heading, TextInput, Label, Paragraph, Textarea, Select, Button, Spinner, Alert } from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';

import 'react-datepicker/dist/react-datepicker.css';

export class EditCancellationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      saveError: false,
      validationErrors: [],
      effectiveDate: '',
      gross: '',
      fee: '',
      transactionDate: '',
      refundMethod: '',
      cancellationIndicator: '',
      isNew: false,
    };
  }

  async componentDidMount() {
    try {
      const urlParams = new URLSearchParams(window.location.search);

      const response = await axios.get(`/bonds/api/locations/${urlParams.get('id')}/cancellation`);

      console.log('response: ', response);

      this.setState({
        loading: false,
        effectiveDate: response.data.removalDate,
        gross: response.data.gross || '',
        fee: response.data.fee || '',
        transactionDate: response.data.transactionDate || '',
        refundMethod: response.data.refundMethod || '',
        cancellationIndicator: (response.data.cancellationIndicator || '').toString(),
      });
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({ loading: false, effectiveDate: new Date(), isNew: true });
      } else {
        console.error(error);
      }
    }
  }

  validate() {
    let errorMessages = [];

    if (!this.state.effectiveDate) {
      errorMessages.push('Effective Date of Cancellation is required.');
    }

    if (this.state.gross) {
      const gross = parseFloat(this.state.gross);
      if (gross > 0) {
        errorMessages.push('Gross must be a negative value.');
      }
    }

    if (this.state.fee) {
      const fee = parseFloat(this.state.fee);
      if (fee < 0) {
        errorMessages.push('Fee must be a positive value.');
      }
    }

    this.setState({ validationErrors: errorMessages });

    return errorMessages.length === 0;
  }

  save = async (e) => {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    this.setState({ saving: true, validationErrors: [] });

    try {
      const urlParams = new URLSearchParams(window.location.search);

      const data = {
        removalDate: this.state.effectiveDate,
        gross: this.state.gross,
        fee: this.state.fee,
        transactionDate: this.state.transactionDate,
        refundMethod: this.state.refundMethod,
        cancellationIndicator: this.state.cancellationIndicator,
      };

      console.log('isNew: ', this.state.isNew);

      if (this.state.isNew) {
        await axios.post(`/bonds/api/locations/${urlParams.get('id')}/cancellation`, data);
      } else {
        await axios.put(`/bonds/api/locations/${urlParams.get('id')}/cancellation`, data);
      }

      window.location.replace(this.getReturnUrl());
    } catch (error) {
      if (error.response.status === 422) {
        this.setState({ validationErrors: error.response.data, saving: false });
      } else {
        console.error(error);
        this.setState({ saveError: true });
      }
    }
  };

  getReturnUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return '/bonds/Administrator/location-changes.aspx?applicationid=' + urlParams.get('applicationid');
  }

  calculateNet() {
    let net = 0;

    if (this.state.gross && this.state.fee && this.state.gross !== '-') {
      net = parseFloat(this.state.gross) + parseFloat(this.state.fee);
    }

    //net should never be greater than zero.
    if (net > 0) {
      net = 0;
    }

    return net.toFixed(2);
  }

  formatDate(input) {
    if (!input) {
      return '';
    }

    return moment(input).toDate();
  }

  effectiveDateChanged = (date) => {
    this.setState({ effectiveDate: date });
  };

  grossChanged = (e) => {
    const value = e.target.value;

    if (!value || value === '-' || value.match(/^-?\d{1,}(\.\d{0,2})?$/)) {
      this.setState(() => ({ gross: value }));
    }
  };

  feeChanged = (e) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState(() => ({ fee: value }));
    }
  };

  transactionDateChanged = (date) => {
    this.setState({ transactionDate: date });
  };

  refundMethodChanged = (e) => {
    this.setState({ refundMethod: e.target.value });
  };

  cancellationIndicatorChanged = (e) => {
    this.setState({ cancellationIndicator: e.target.value });
  };

  render() {
    let content = (
      <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
        <Spinner />
      </Pane>
    );

    if (!this.state.loading) {
      let message = '';
      if (this.state.saveError) {
        message = <Alert intent="danger" marginBottom={24} title="Error saving changes." />;
      } else if (this.state.validationErrors.length > 0) {
        message = (
          <Alert intent="danger" marginBottom={24} title="Please correct the following:">
            <ul>
              {this.state.validationErrors.map((msg, index) => {
                return <li key={index}>{msg}</li>;
              })}
            </ul>
          </Alert>
        );
      }
      content = (
        <Pane>
          <Heading marginBottom={24} size={800}>
            Edit Cancellation Info
          </Heading>
          {message}
          <Pane borderBottom="muted" borderTop="muted">
            <Label display="block" marginBottom={4} htmlFor="effective-date">
              Effective Date of Cancellation *
            </Label>
            <DatePicker selected={this.formatDate(this.state.effectiveDate)} onChange={this.effectiveDateChanged} customInput={<TextInput id="effective-date" width={150} />} />
            <Paragraph marginTop={6} size={300} color="muted"></Paragraph>
          </Pane>
          <Pane borderBottom="muted">
            <Label display="block" marginBottom={4} htmlFor="gross">
              Gross Refund Amount
            </Label>
            <TextInput id="gross" onChange={this.grossChanged} value={this.state.gross} width={150} />
            <Paragraph marginTop={6} size={300} color="muted">
              Must Enter Negative Amount
            </Paragraph>
          </Pane>
          <Pane borderBottom="muted">
            <Label display="block" marginBottom={4} htmlFor="fee">
              Fee
            </Label>
            <TextInput id="fee" onChange={this.feeChanged} value={this.state.fee} width={150} />
            <Paragraph marginTop={6} size={300} color="muted">
              Funds collected for fee (VGM Keeps / Must be Positive (+))
            </Paragraph>
          </Pane>
          <Pane borderBottom="muted">
            <Label display="block" marginBottom={4}>
              Net
            </Label>
            {this.calculateNet()}
            <Paragraph marginTop={6} size={300} color="muted">
              If eligible for a refund, amount to REFUND Credit Card or have accounting issue the refund check for $
            </Paragraph>
          </Pane>
          <Pane borderBottom="muted">
            <Label display="block" marginBottom={4} htmlFor="transaction-date">
              Transaction Date
            </Label>
            <DatePicker
              selected={this.formatDate(this.state.transactionDate)}
              onChange={this.transactionDateChanged}
              customInput={<TextInput id="transaction-date" width={150} />}
            />
            <Paragraph marginTop={6} size={300} color="muted">
              Date the refund is actually processed in credit card system or the date the check was issued by accounting
            </Paragraph>
          </Pane>
          <Pane borderBottom="muted">
            <Label display="block" marginBottom={4} htmlFor="transaction-date">
              Refund Method
            </Label>
            <Textarea onChange={this.refundMethodChanged} value={this.state.refundMethod} />
            <Paragraph marginTop={6} size={300} color="muted">
              The Type of credit card and the last 4 digits of credit card # (A=AMEX, V=Visa, M=Mastercard, D=Discover, C=Check + Check Number)
            </Paragraph>
          </Pane>
          <Pane borderBottom="muted">
            <Label display="block" marginBottom={4} htmlFor="cancellation-indicator">
              Cancellation Indicator
            </Label>
            <Select id="cancellation-indicator" onChange={this.cancellationIndicatorChanged} value={this.state.cancellationIndicator}>
              <option value="">(Choose One)</option>
              <option value="1">Flat</option>
              <option value="2">Prorated</option>
            </Select>
            <Paragraph marginTop={6} size={300} color="muted"></Paragraph>
          </Pane>
          <Pane display="flex" marginTop={24}>
            <Pane flex={1}></Pane>
            <Pane>
              <a href={this.getReturnUrl()}>[Cancel]</a>
              <Button appearance="primary" marginLeft={16} onClick={this.save} isLoading={this.state.saving}>
                Save
              </Button>
            </Pane>
          </Pane>
        </Pane>
      );
    }

    return content;
  }
}
