import React from 'react';
import PropTypes from 'prop-types';
import { ProgressSpinner } from 'primereact/progressspinner';

const SmallSpinner = ({ text }) => {
    return <div>{text} <ProgressSpinner style={{ width: '25px', height: '25px' }} strokeWidth="8" /></div>;
}

SmallSpinner.propTypes = {
    text: PropTypes.string,
};

export default SmallSpinner;
