import React from 'react';
import PropTypes from 'prop-types';

import { ProgressSpinner } from 'primereact/progressspinner';

const Spinner = ({ message }) => {
  return (
    <center>
      <ProgressSpinner />
      <br />
      {message}...
    </center>
  );
};

Spinner.propTypes = {
  message: PropTypes.string,
};

export default Spinner;
