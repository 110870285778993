import React, { useState } from 'react';

import ManageBondNumbersDialog from './manage-bond-numbers/ManageBondNumbersDialog.jsx';

export default function Reporting() {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      ,{' '}
      <a href="#" onClick={() => setShowDialog(true)}>
        Manage Bond Numbers
      </a>
      <ManageBondNumbersDialog show={showDialog} onClosed={() => setShowDialog(false)} />
    </>
  );
}
