import React from 'react';
import PropTypes from 'prop-types';

import './Error.scss';

const Error = ({ title, message, details }) => {
  return (
    <div className="error-container">
      <center>
        <i className="pi pi-exclamation-circle" style={{ fontSize: '5em', color: 'red' }}></i>
        <br />
        <p>
          <strong>{title}</strong>
        </p>
        <p>Message: {message || 'ConceptOne error'}</p>
        {!details || (
          <p>
            <textarea rows={10} cols={100} readOnly defaultValue={details} />
          </p>
        )}
      </center>
    </div>
  );
};

Error.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  details: PropTypes.string,
};

export default Error;
