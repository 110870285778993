import React from 'react';
import { Select } from 'evergreen-ui';
import PropTypes from 'prop-types';

class SubEntitySelector extends React.Component {
  render() {
    if (this.props.subEntities.length > 0) {
      return (
        <Select value={this.props.selectedSubEntity} onChange={this.props.onChange} width="100%">
          {this.props.subEntities.map(entity => {
            return (
              <option key={entity.id} value={entity.id}>
                {entity.reference}
              </option>
            );
          })}
        </Select>
      );
    } else {
      return null;
    }
  }
}

SubEntitySelector.propTypes = {
  subEntities: PropTypes.array.isRequired,
  selectedSubEntity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default SubEntitySelector;
