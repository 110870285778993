import React from 'react';
import PropTypes from 'prop-types';

const Success = ({ message }) => {
  return (
    <div>
      <center>
        <i className="pi pi-check-circle" style={{ fontSize: '5em', color: 'green' }}></i>
        <br />
        {message}
      </center>
    </div>
  );
};

Success.propTypes = {
  message: PropTypes.string,
};

export default Success;
