import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Spinner from '../../integrations/dialog/Spinner';
import SignatureUpload from './SignatureUpload.jsx';

function AddSignatureDialog({ showAddSignatureDialog, onClosed, existingSignatureUserIds }) {
  const [emailFilter, setEmailFilter] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const searchUsers = async (event) => {
    event.preventDefault();
    setIsSearching(true);
    try {
      const response = await fetch(`/bonds/api/users?emailFilter=${emailFilter}&role=processor`);
      if (response.ok) {
        let json = await response.json();
        console.log('json: ', json);
        json = json.filter((f) => !existingSignatureUserIds.includes(f.userID));
        setSearchResults(json);
      } else {
        throw response;
      }
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <Dialog header="Add Signature" visible={showAddSignatureDialog} onHide={onClosed} modal={true} style={{ width: '50vw' }}>
      <h1>Add Signature</h1>

      <form onSubmit={searchUsers}>
        <div className="p-formgroup-inline">
          <div className="p-field">
            <label>Search Underwriter Email:</label>
            <InputText value={emailFilter} onChange={(e) => setEmailFilter(e.target.value)} />
          </div>
          <div className="p-field">
            <label>&nbsp;</label>
            <Button label="Search" />
          </div>
        </div>
      </form>
      <div className="p-field">
        <label>Select a User</label>
        <div>
          {isSearching ? (
            <Spinner message={'Searching...'} />
          ) : (
            <DataTable value={searchResults} selectionMode="single" selection={selectedUser} onSelectionChange={(e) => setSelectedUser(e.value)} dataKey="userID">
              <Column field="userName" header="UserName"></Column>
              <Column field="email" header="Email"></Column>
            </DataTable>
          )}
        </div>
      </div>
      {selectedUser && (
        <div className="p-field p-grid">
          <label className="p-col-12 p-md-2">Signature</label>
          <div className="p-col-12 p-md-10">
            <SignatureUpload
              userID={selectedUser.userID}
              onComplete={async () => {
                setSelectedUser();
                onClosed();
              }}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
}

AddSignatureDialog.propTypes = {
  showAddSignatureDialog: PropTypes.bool.isRequired,
  onClosed: PropTypes.func.isRequired,
  existingSignatureUserIds: PropTypes.array.isRequired,
};

export default AddSignatureDialog;
