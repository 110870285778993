import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';

const ChooseDialog = ({ selectedContract, showChooseContractDialog, onChooseContractDialogClosed, onCreateContractClicked, consumer, onLinkContract, onRenewContractClicked }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);

  const onShow = async () => {
    try {
      const response = await axios.get(`/bonds/api/consumers/${consumer.consumerID}/contracts`);
      setSearchResults(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const contractChosen = (conceptOneContractID) => {
    onChooseContractDialogClosed();
    onLinkContract(selectedContract.bondID, selectedContract.renewalID, conceptOneContractID);
  };

  const selectTemplate = (rowData) => {
    const { conceptOneContractID } = rowData;
    
    return <div data-contract-id={conceptOneContractID}><Button label="Select" type="button" onClick={() => contractChosen(conceptOneContractID)} /></div>;
  };

  const termTemplate = (rowData) => {
    return (
      <span>
        {moment(rowData.effectiveDate).format('l')} - {moment(rowData.expiryDate).format('l')}
      </span>
    );
  };

  const onCreateNewClicked = () => {
    onChooseContractDialogClosed();
    onCreateContractClicked();
  };

  const onRenewClicked = () => {
    onChooseContractDialogClosed();
    onRenewContractClicked();
  };

  let createSection = <></>;
  if (selectedContract) {
    if (selectedContract.type === 'bond') {
      createSection = <Button label="Create New Contract" style={{ marginTop: '15px' }} onClick={onCreateNewClicked} type="button" />;
    } else if (selectedContract.type === 'renewal' && selectedContract.previousContract && selectedContract.previousContract.contractID) {
      createSection = <Button label="Create New Renewal" style={{ marginTop: '15px' }} onClick={onRenewClicked} type="button" />;
    } else {
      createSection = <Message severity="warn" style={{ marginTop: '15px' }} text="A new renewal cannot be created until the previous contract is linked." />;
    }
  }

  return (
    <Dialog header="Choose Contract" visible={showChooseContractDialog} modal={true} onHide={onChooseContractDialogClosed} onShow={onShow} style={{ width: '50vw' }}>
      <div className="p-field p-grid">
        <label className="p-col-fixed bold" style={{ width: '75px' }}>
          Type:
        </label>
        <div className="p-col">{!selectedContract || (selectedContract.type === 'bond' ? 'Bond' : 'Bond Renewal')}</div>
      </div>
      <div className="p-field p-grid">
        <label className="p-col-fixed bold" style={{ width: '75px' }}>
          Bond:
        </label>
        <div className="p-col">{!selectedContract || selectedContract.bondNumber}</div>
      </div>
      <div className="p-field p-grid">
        <label className="p-col-fixed bold" style={{ width: '75px' }}>
          Location:
        </label>
        <div className="p-col">{!selectedContract || selectedContract.companyName}</div>
      </div>
      <div className="p-field p-grid top">
        <label className="p-col-fixed bold" style={{ width: '75px' }}>
          Term:
        </label>
        <div className="p-col">{!selectedContract || selectedContract.term}</div>
      </div>
      <DataTable value={searchResults} scrollable={true} scrollHeight="200px" style={{ marginTop: '30px' }} loading={isLoading} emptyMessage="No contracts available.">
        <Column field="policyNumber" header="Policy" sortable={true} />
        <Column field="transactionType" header="TransactionType" sortable={true} />
        <Column field="effectiveDate" header="Term" sortable={true} body={termTemplate} />
        <Column field="status" header="Status" sortable={true} />
        <Column style={{ width: '100px' }} body={selectTemplate} />
      </DataTable>
      {createSection}
    </Dialog>
  );
};

ChooseDialog.propTypes = {
  selectedContract: PropTypes.object,
  showChooseContractDialog: PropTypes.bool.isRequired,
  onChooseContractDialogClosed: PropTypes.func.isRequired,
  onCreateContractClicked: PropTypes.func.isRequired,
  consumer: PropTypes.object,
  onLinkContract: PropTypes.func.isRequired,
  onRenewContractClicked: PropTypes.func.isRequired,
};

export default ChooseDialog;
