import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'primereact/button';

const Footer = ({ mode, onYes, onNo, onClose }) => {
  if (mode?.toLowerCase() == 'yesno') {
    return (
      <div>
        <Button label="Yes" icon="pi pi-check" onClick={onYes} type="button" />
        <Button label="No" icon="pi pi-times" onClick={onNo} type="button" />
      </div>
    );
  } else if (mode?.toLowerCase() == 'close') {
    return (
      <div>
        <Button label="Close" icon="pi pi-times" onClick={onClose} type="button" />
      </div>
    );
  }

  return null;
};

Footer.propTypes = {
  mode: PropTypes.oneOf(['YesNo', 'Close', null]),
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  onClose: PropTypes.func,
};

export default Footer;
