import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import Footer from '../dialog/Footer';

function IgnoreDialog({ error, show, onClosed, onIgnored }) {
  const [spin, setSpin] = useState(false);

  async function onIgnoreConfirm() {
    setSpin(true);
    const response = await fetch(`/bonds/api/contractIntegrationStatus/${error.contractIntegrationStatusID}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      setSpin(false);
      onClosed();
      onIgnored();
    } else {
      throw response;
    }
  }

  const footer = spin || <Footer mode="YesNo" onNo={onClosed} onYes={onIgnoreConfirm} />;

  return (
    <Dialog header="Ignore Error" visible={show} modal={true} onHide={onClosed} style={{ width: '50vw' }} footer={footer}>
      {spin ? (
        <center>
          <ProgressSpinner />
          <br />
          Saving...
        </center>
      ) : (
        <span>Are you sure you want to ignore this error? This should only be done when the operation is manually completed in ConceptOne.</span>
      )}
    </Dialog>
  );
}

IgnoreDialog.propTypes = {
  error: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onClosed: PropTypes.func.isRequired,
  onIgnored: PropTypes.func.isRequired,
};

export default IgnoreDialog;
