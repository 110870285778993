import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import _ from 'underscore';
import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';

import Spinner from '../../integrations/dialog/Spinner';
import ButtonMenu from '../../common/ButtonMenu';

function ActionButtons({ rowData, editingRow, onSetEditingRow, onCancel }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showButtonId] = useState(_.uniqueId('preview-report-dropdown-'));
  const [showSpinner, setShowSpinner] = useState(false);

  const availableReports = [
    { name: 'Generic Bond Rider', key: 'generic-bond-rider' },
    { name: 'Florida Medicaid Continuation Form', key: 'florida-medicaid-continuation-form' },
    { name: 'Texas Medicaid Continuation Form', key: 'texas-medicaid-continuation-form' },
    { name: 'Generic Cancellation Letter', key: 'generic-cancellation-letter' },
    { name: 'Florida Medicaid Bond', key: 'florida-medicaid-bond' },
    { name: 'Indiana Medicaid Bond', key: 'indiana-medicaid-bond' },
    { name: 'Alabama Medicaid Bond', key: 'alabama-medicaid-bond' },
    { name: 'Texas Medicaid Bond', key: 'texas-medicaid-bond' },
    { name: 'Medicare Bond', key: 'medicare' },
    { name: 'Indiana Medicaid Cancellation Letter', key: 'indiana-medicaid-cancellation-letter' },
    { name: 'Maine Medicaid Bond', key: 'maine-medicaid-bond' },
    { name: 'Maine Medicaid Continuation Form', key: 'maine-medicaid-continuation-form' },
    { name: 'Indiana Medicaid Bond Rider', key: 'indiana-medicaid-bond-rider' },
    { name: 'Minnesota DMEPOS Bond Form', key: 'minnesota-dmepos-bond' },
    { name: 'Minnesota PCA Bond Form', key: 'minnesota-pca-bond' },
    { name: 'Bond Reinstatement Form', key: 'bond-reinstatement-form' },
  ];

  const generateReport = async (reportName) => {
    setShowSpinner(true);

    const response = await fetch(`/bonds/api/reporting/${reportName}/test?userId=${rowData.userID ?? ''}`);
    if (response.ok) {
      const blob = await response.blob();
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
      setShowMenu(false);
      setShowSpinner(false);
    } else {
      throw response;
    }
  };

  let editButton = <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => onSetEditingRow(rowData)} />;
  if (rowData === editingRow) {
    editButton = (
      <Button
        id=""
        icon="pi pi-undo"
        className="p-button-rounded p-button-danger p-mr-2"
        onClick={() => {
          onSetEditingRow();
          onCancel();
        }}
      />
    );
  }

  return (
    <>
      {editButton}
      <Button id={showButtonId} icon="pi pi-search" className="p-button-rounded p-button-secondary" onClick={() => setShowMenu(true)} />
      <ButtonMenu targetElementId={showButtonId} isVisible={showMenu} onHide={() => setShowMenu(false)}>
        {showSpinner ? (
          <Spinner message={'Loading'} />
        ) : (
          <ul>
            {availableReports
              .sort((firstItem, secondItem) => {
                let first = firstItem.name.toUpperCase();
                let second = secondItem.name.toUpperCase();
                return first === second ? 0 : first > second ? 1 : -1;
              })
              .map((report, index) => (
                <li key={index}>
                  <Button label={report.name} className="p-button-text p-button-plain" onClick={() => generateReport(report.key)} />
                </li>
              ))}
          </ul>
        )}
      </ButtonMenu>
    </>
  );
}

ActionButtons.propTypes = {
  rowData: PropTypes.object.isRequired,
  editingRow: PropTypes.object,
  onSetEditingRow: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ActionButtons;
