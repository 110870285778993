import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

ChooseDialog.propTypes = {
  showChooseConsumerDialog: PropTypes.bool.isRequired,
  onChooseConsumerDialogClosed: PropTypes.func.isRequired,
  onLinkConsumer: PropTypes.func.isRequired,
  onCreateConsumerClicked: PropTypes.func.isRequired,
};

function ChooseDialog({ showChooseConsumerDialog, onChooseConsumerDialogClosed, onLinkConsumer, onCreateConsumerClicked }) {
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState('');
  const [searchWasDone, setSearchWasDone] = useState(false);
  const [name, setName] = useState('');
  const [fein, setFein] = useState('');

  async function onSearchClicked(e) {
    e.preventDefault();
    await search(name, fein);
  }

  async function search(name, fein) {
    setIsSearching(true);

    try {
      const response = await axios.get(`/bonds/api/consumers/search?fein=${fein}&name=${name}`);
      setEmptyMessage('No Consumers Found');
      setSearchResults(response.data);
      setIsSearching(false);
      setSearchWasDone(true);
    } catch (error) {
      console.error(error);
    }
  }

  function onConsumerSelect(consumerId) {
    onLinkConsumer(consumerId);
    onChooseConsumerDialogClosed();
  }

  function onCreateNewClicked() {
    onChooseConsumerDialogClosed();
    onCreateConsumerClicked();
  }

  function addressTemplate(rowData) {
    return (
      <span>
        {rowData.address}
        <br />
        {rowData.city}, {rowData.state} {rowData.postalCode}
      </span>
    );
  }

  function selectTemplate(rowData) {
    return <Button label="Select" type="button" onClick={() => onConsumerSelect(rowData.consumerId)} data-consumer-id={rowData.consumerId} />;
  }

  async function onShow() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.get(`/bonds/api/applications/${urlParams.get('applicationid')}/locations`);
      const newFein = response.data.find((c) => c.isMain).fein;
      setFein(newFein);
      setName('');
      await search('', newFein);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Dialog header="Choose Consumer" visible={showChooseConsumerDialog} modal={true} onHide={onChooseConsumerDialogClosed} onShow={onShow} style={{ width: '50vw' }}>
      <form onSubmit={onSearchClicked}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="name">Name</label>
            <InputText id="name" type="text" onChange={(e) => setName(e.target.value)} value={name} />
          </div>
          <div className="p-field p-col-12 p-md-6"></div>
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="fein">FEIN</label>
            <InputText id="fein" type="text" onChange={(e) => setFein(e.target.value)} value={fein} />
          </div>
          <div className="p-field p-col-12 p-md-6"></div>
        </div>
        <Button label="Search" disabled={!name && !fein} />
      </form>
      <DataTable value={searchResults} scrollable={true} scrollHeight="200px" style={{ marginTop: '30px' }} loading={isSearching} emptyMessage={emptyMessage}>
        <Column field="name" header="Name" sortable={true} />
        <Column field="fein" header="FEIN" sortable={true} />
        <Column field="address" header="Address" body={addressTemplate} sortable={true} />
        <Column style={{ width: '100px' }} body={selectTemplate} />
      </DataTable>
      <Button label="Create New Consumer" disabled={!searchWasDone} style={{ marginTop: '15px' }} onClick={onCreateNewClicked} type="button" />
    </Dialog>
  );
}

export default ChooseDialog;
