import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';

const ConfirmDialog = ({ header, message, show, onYes, onHide }) => {
  const footer = (
    <div>
      <Button label="Yes" icon="pi pi-check" onClick={onYes} type="button" />
      <Button label="No" icon="pi pi-times" onClick={onHide} type="button" />
    </div>
  );

  return (
    <Dialog header={header} footer={footer} visible={show} modal={true} onHide={onHide}>
      {message}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  onYes: PropTypes.func,
  onHide: PropTypes.func,
};

export default ConfirmDialog;
