import React from 'react';
import { render } from 'react-dom';
import Reporting from './administration/Reporting.jsx';
import ManageBondNumbers from './administration/ManageBondNumbers.jsx';
import Notifications from './notifications/Notifications.jsx';
import { AuditModal } from './audit/AuditModal.js';
import Integrations from './integrations/Integrations.jsx';
import { EditCancellationInfo } from './cancellation/EditCancellationInfo.js';
import IntegrationsDataEntry from './integrations/IntegrationsDataEntry.jsx';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

function renderToIdIfExists(element, id) {
  const renderTo = document.getElementById(id);
  if (renderTo) {
    render(element, renderTo);
  }
}

renderToIdIfExists(<Reporting />, 'administration-reporting-app');
renderToIdIfExists(<ManageBondNumbers />, 'administration-bond-numbers-app');
renderToIdIfExists(<Notifications />, 'notifications-app');
renderToIdIfExists(<AuditModal />, 'audit-app');
renderToIdIfExists(<Integrations />, 'integrations-app');
renderToIdIfExists(<EditCancellationInfo />, 'edit-cancellation-info-app');
renderToIdIfExists(<IntegrationsDataEntry />, 'integrations-data-entry-app');
