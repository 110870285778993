import React from 'react';
import { SideSheet, Position, Heading, Pane, majorScale, Button } from 'evergreen-ui';
import Moment from 'react-moment';
import ReactDiffViewer from 'react-diff-viewer';
import PropTypes from 'prop-types';

class DiffSlider extends React.Component {
  render() {
    return (
      <SideSheet
        position={Position.TOP}
        isShown={this.props.showDiff}
        onCloseComplete={this.props.diffSideSheetWasClosed}
      >
        <Heading size={600} paddingTop={majorScale(1)} paddingBottom={majorScale(2)} paddingLeft={majorScale(1)}>
          Changes made on <Moment format="MM-DD-YYYY hh:mm:ss A">{this.props.diffDate}</Moment>
        </Heading>
        <ReactDiffViewer
          oldValue={this.props.previousDiff}
          newValue={this.props.currentDiff}
          splitView={true}
          hideLineNumbers={true}
          disableWordDiff={true}
          extraLinesSurroundingDiff={10000} //arbitrarily large number
        />
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(2)}
        >
          <Button
            iconBefore="arrow-left"
            disabled={!this.props.enableDiffPrevious}
            onClick={this.props.showPreviousDiff}
          >
            Previous
          </Button>
          <Button
            marginLeft={majorScale(3)}
            iconAfter="arrow-right"
            disabled={!this.props.enableDiffNext}
            onClick={this.props.showNextDiff}
          >
            Next
          </Button>
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop={majorScale(1)}
          paddingBottom={majorScale(2)}
        >
          <Button marginLeft={majorScale(3)} onClick={this.props.diffSideSheetWasClosed}>
            Close
          </Button>
        </Pane>
      </SideSheet>
    );
  }
}

DiffSlider.propTypes = {
  showDiff: PropTypes.bool.isRequired,
  diffSideSheetWasClosed: PropTypes.func.isRequired,
  diffDate: PropTypes.string.isRequired,
  previousDiff: PropTypes.string.isRequired,
  currentDiff: PropTypes.string.isRequired,
  enableDiffPrevious: PropTypes.bool.isRequired,
  showPreviousDiff: PropTypes.func.isRequired,
  enableDiffNext: PropTypes.bool.isRequired,
  showNextDiff: PropTypes.func.isRequired,
};

export default DiffSlider;
