import React from 'react';
import { Dialog, Pane, Spinner, Alert, majorScale, SegmentedControl } from 'evergreen-ui';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import AuditList from './AuditList';
import SubEntitySelector from './SubEntitySelector';
import entityTypeFormatter from './entityTypeFormatter';
import jsonDiffFormatter from './jsonDiffFormatter';
import DiffSlider from './DiffSlider';

import './AuditModal.scss';

export class AuditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAuditList: false,
      loading: true,
      auditEvents: [],
      showDiff: false,
      previousDiff: '',
      currentDiff: '',
      diffDate: '',
      enableDiffPrevious: false,
      enableDiffNext: false,
      currentDiffIndex: null,
      selectedEntity: 'Application',
      tabs: [],
      filteredAuditEvents: [],
      subEntities: [],
      selectedSubEntity: null,
    };
  }

  showHistory = async (event) => {
    event.preventDefault();

    this.setState({ showAuditList: true });

    try {
      const urlParams = new URLSearchParams(window.location.search);

      const response = await axios.get(`/bonds/api/applications/${urlParams.get('applicationid')}/auditevents`);

      const events = _.sortBy(response.data, (evt) => moment(evt.sequence));
      const entity = [...new Set(events.map((evt) => evt.entity))];

      const tabs = entity
        .map((type) => {
          return { label: entityTypeFormatter(type), value: type };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      this.setState({ auditEvents: events, loading: false, tabs: tabs }, () => {
        if (tabs.length > 0) {
          if (tabs.indexOf('Application') >= 0) {
            this.tabChanged('Application');
          } else {
            this.tabChanged(tabs[0].value);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  onClose = () => {
    this.setState({ showAuditList: false });
  };

  showDiff = (evt) => {
    const previousEventSnapshot = !this.isFirstEvent(evt) ? jsonDiffFormatter(this.state.filteredAuditEvents[this.state.filteredAuditEvents.indexOf(evt) - 1].snapshot) : '';

    this.setState({
      showDiff: true,
      currentDiff: jsonDiffFormatter(evt.snapshot),
      previousDiff: previousEventSnapshot,
      diffDate: evt.eventDate,
      enableDiffPrevious: !this.isFirstEvent(evt),
      enableDiffNext: !this.isLastEvent(evt),
      currentDiffIndex: this.state.filteredAuditEvents.indexOf(evt),
    });
  };

  diffSideSheetWasClosed = () => {
    this.setState({
      showDiff: false,
      currentDiff: '',
      previousDiff: '',
      diffDate: '',
      enableDiffPrevious: false,
      enableDiffNext: false,
      currentDiffIndex: null,
    });
  };

  showPreviousDiff = () => {
    this.showDiff(this.state.filteredAuditEvents[this.state.currentDiffIndex - 1]);
  };

  showNextDiff = () => {
    this.showDiff(this.state.filteredAuditEvents[this.state.currentDiffIndex + 1]);
  };

  isFirstEvent = (evt) => {
    return this.state.filteredAuditEvents.indexOf(evt) === 0;
  };

  isLastEvent = (evt) => {
    return this.state.filteredAuditEvents.indexOf(evt) === this.state.filteredAuditEvents.length - 1;
  };

  tabChanged = (tab) => {
    const auditEventsForEntity = this.state.auditEvents.filter((evt) => evt.entity === tab);

    const entityIDs = [
      ...new Set(
        auditEventsForEntity
          .filter((evt) => evt.entityID)
          .map((evt) => {
            return evt.entityID;
          }),
      ),
    ];

    auditEventsForEntity.reverse();

    const subEntities = entityIDs
      .map((id) => {
        return { id: id, reference: auditEventsForEntity.find((evt) => evt.entityID === id).entityReference };
      })
      .sort((a, b) => a.reference.localeCompare(b.reference));

    const selectedSubEntity = subEntities.length > 0 ? subEntities[0].id : null;

    this.setState(
      {
        selectedEntity: tab,
        subEntities: subEntities,
        selectedSubEntity: selectedSubEntity,
      },
      () => this.buildFilteredAuditEvents(),
    );
  };

  buildFilteredAuditEvents = () => {
    let filteredAuditEvents = this.state.auditEvents.filter((evt) => evt.entity === this.state.selectedEntity);
    if (this.state.selectedSubEntity) {
      filteredAuditEvents = filteredAuditEvents.filter((evt) => evt.entityID === this.state.selectedSubEntity);
    }

    this.setState({ filteredAuditEvents: filteredAuditEvents });
  };

  selectedSubEntityChanged = (e) => {
    this.setState(
      {
        selectedSubEntity: parseInt(e.target.value),
      },
      () => this.buildFilteredAuditEvents(),
    );
  };

  render() {
    let header = null;
    let content = (
      <Pane display="flex" alignItems="center" justifyContent="center" height={majorScale(50)}>
        <Spinner />
      </Pane>
    );

    if (!this.state.loading && this.state.auditEvents.length > 0) {
      header = <SegmentedControl options={this.state.tabs} value={this.state.selectedEntity} onChange={this.tabChanged} />;
      if (this.state.filteredAuditEvents.length > 0) {
        content = (
          <>
            <SubEntitySelector subEntities={this.state.subEntities} selectedSubEntity={this.state.selectedSubEntity} onChange={this.selectedSubEntityChanged} />
            <AuditList auditEvents={this.state.filteredAuditEvents} onShowDiff={this.showDiff} />
            <DiffSlider
              showDiff={this.state.showDiff}
              diffSideSheetWasClosed={this.diffSideSheetWasClosed}
              diffDate={this.state.diffDate}
              previousDiff={this.state.previousDiff}
              currentDiff={this.state.currentDiff}
              enableDiffPrevious={this.state.enableDiffPrevious}
              showPreviousDiff={this.showPreviousDiff}
              enableDiffNext={this.state.enableDiffNext}
              showNextDiff={this.showNextDiff}
            />
          </>
        );
      } else {
        content = <Alert intent="none" title="No audit events." marginBottom={32} />;
      }
    } else {
      content = <Alert intent="none" title="No audit events." marginBottom={32} />;
    }

    return (
      <>
        <a href="#" onClick={this.showHistory}>
          Show History
        </a>
        <Dialog
          isShown={this.state.showAuditList}
          title="History"
          onCloseComplete={this.onClose}
          confirmLabel="Close"
          hasCancel={false}
          preventBodyScrolling={true}
          width={majorScale(120)}
        >
          {header}
          {content}
        </Dialog>
      </>
    );
  }
}
