/* global $ */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import axios from 'axios';

import { Dialog } from 'primereact/dialog';

import Footer from '../dialog/Footer';
import Error from '../dialog/Error';
import Success from '../dialog/Success';
import Spinner from '../dialog/Spinner';

function CancelDialog({ selectedContract: contract, show, onClosed, onCancelSuccessClosed }) {
  const [showYesNoButtons, setShowYesNoButtons] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [savingMessage, setSavingMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorDetails, setErrorDetails] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [bondDetails, setBondDetails] = useState({});

  if (!contract) {
    return null;
  }

  async function reset() {
    setShowYesNoButtons(true);
    setShowCloseButton(false);
    setIsSaving(false);
    setShowSuccess(false);
    setShowError(false);
    setSavingMessage('Saving...');
    setIsLoading(true);

    try {
      const response = await axios.get(`/bonds/api/bonds/${contract.bondID}/`);
      setBondDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function onCancelConfirm() {
    setShowYesNoButtons(false);
    setIsSaving(true);

    const instance = uuidv4();

    const connection = $.hubConnection('/bonds/signalr', { useDefaultPath: false });
    const proxy = connection.createHubProxy('genericMessageHub');
    proxy.on('message', (messageInstance, message) => {
      if (messageInstance == instance) {
        setSavingMessage(message);
      }
    });
    await connection.start();

    try {
      const response = await axios.post(`/bonds/api/contracts/${contract.contractID}/cancel`, {
        connectionId: connection.id,
        instance: instance,
      });
      setShowCloseButton(true);
      if (!response.data.wasSuccess) {
        setErrorMessage(response.data.errorMessage);
        setErrorDetails(response.data.errorDetails);
        setShowError(true);
      } else {
        setShowSuccess(true);
      }

      setIsSaving(false);
    } catch (error) {
      console.error(error);
    }
  }

  function onDialogClose() {
    if (showSuccess) {
      onCancelSuccessClosed();
    } else {
      onClosed();
    }
  }

  let mode = null;
  if (showYesNoButtons) {
    mode = 'YesNo';
  } else if (showCloseButton) {
    mode = 'Close';
  }
  const footer = isLoading || <Footer mode={mode} onNo={onClosed} onYes={onCancelConfirm} onClose={onDialogClose} />;

  return (
    <Dialog header="Cancel Contract" visible={show} modal={true} onHide={onClosed} style={{ width: '50vw' }} footer={footer} onShow={reset}>
      {(() => {
        if (isLoading) {
          return <Spinner message={'Loading...'} />;
        } else if (isSaving) {
          return <Spinner message={savingMessage} />;
        } else if (showSuccess) {
          return <Success message={'Contract cancelled successfully!'} />;
        } else if (showError) {
          return <Error title={'Error when cancelling contracts.'} message={errorMessage} details={errorDetails} />;
        } else {
          return (
            <>
              <p>Are you sure you want to cancel this contract in ConceptOne?</p>
              <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-grid p-col">
                  <label className="p-col-fixed width-200-px">ConceptOne Status</label>
                  <div className="p-col">{bondDetails.conceptOneStatus}</div>
                </div>
              </div>
              <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-grid p-col">
                  <label className="p-col-fixed width-200-px">Effective Date</label>
                  <div className="p-col">{moment(contract.cancellationEffectiveDate).format('l')}</div>
                </div>
              </div>
              <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-grid p-col">
                  <label className="p-col-fixed width-200-px">Reason</label>
                  <div className="p-col">{bondDetails.cancellationReason}</div>
                </div>
              </div>
            </>
          );
        }
      })()}
    </Dialog>
  );
}

CancelDialog.propTypes = {
  selectedContract: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onClosed: PropTypes.func.isRequired,
  onCancelSuccessClosed: PropTypes.func.isRequired,
};

export default CancelDialog;
