import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CancelDialog from './contracts/CancelDialog.jsx';
import ChooseDialog from './contracts/ChooseDialog.jsx';
import RenewDialog from './contracts/RenewDialog.jsx';
import CreateDialog from './contracts/CreateDialog.jsx';
import IgnoreDialog from './contracts/IgnoreDialog.jsx';
import Error from './dialog/Error';

function Contracts({ bonds, consumer, onContractCreated, onLinkContract, onContractCancelled, onErrorIgnored }) {
  const [showChooseContractDialog, setShowChooseContractDialog] = useState(false);
  const [showCreateContractDialog, setShowCreateContractDialog] = useState(false);
  const [showRenewContractDialog, setShowRenewContractDialog] = useState(false);
  const [selectedContract, setSelectedContract] = useState();
  const [showViewErrorDialog, setShowViewErrorDialog] = useState(false);
  const [errorInfoToView, setErrorInfoToView] = useState();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showIgnoreDialog, setShowIgnoreDialog] = useState(false);

  function getContractStatus(contractID) {
    if (!contractID) {
      return { isLinked: false };
    }

    const contract = consumer.contracts.find((c) => c.contractID === contractID);

    let status = null;
    let conceptOneContractID = '';
    if (contract) {
      status = contract.status;
      conceptOneContractID = contract.conceptOneContractID;
    }

    return { isLinked: contract, status, conceptOneContractID };
  }

  function onLinkClicked(nodeData) {
    setSelectedContract(nodeData);
    setShowChooseContractDialog(true);
  }

  function onIgnoreClicked(nodeData) {
    setSelectedContract(nodeData);
    setShowIgnoreDialog(true);
  }

  function onCancelClicked(nodeData) {
    setSelectedContract(nodeData);
    setShowCancelDialog(true);
  }

  function onChooseDialogClosed() {
    setShowChooseContractDialog(false);
  }

  function viewErrorClicked(e, integrationStatus, nodeData) {
    e.preventDefault();
    console.log('integrationStatus:', integrationStatus);
    const { action, errorMessage, errorDetails, contractIntegrationStatusID } = integrationStatus;

    setErrorInfoToView({
      title: `Error when ${action === 1 ? 'creating' : 'cancelling'} contract`,
      errorMessage,
      errorDetails,
      nodeData: nodeData,
      action,
      contractIntegrationStatusID,
    });

    setShowViewErrorDialog(true);
  }

  function viewErrorDialogClosed() {
    setShowViewErrorDialog(false);
    setErrorInfoToView();
  }

  function statusTemplate(node) {
    const { status, bondID, renewalID } = node.data;
    const { isLinked, status: statusSummary } = status;

    const integrationStatus = consumer.integrationStatuses.find((c) => c.bondID === bondID && c.renewalID == renewalID);

    if (!isLinked) {
      if (integrationStatus) {
        return showIntegrationError(integrationStatus, node);
      }

      return <Message severity="warn" text="Not linked" />;
    }

    if (integrationStatus) {
      return showIntegrationError(integrationStatus, node);
    }

    return (
      <span>
        <strong>Status: </strong> {statusSummary.status}
        <br />
        <strong>Contract Status: </strong> {statusSummary.contractStatus}
      </span>
    );
  }

  function showIntegrationError(integrationStatus, node) {
    const { status } = integrationStatus;
    const { data } = node;

    if (status.toLowerCase() == 'error') {
      return (
        <div>
          <Message severity="error" text="Error" />{' '}
          <a
            href="#"
            onClick={(e) => {
              viewErrorClicked(e, integrationStatus, data);
            }}
          >
            <i className="pi pi-search" style={{ fontSize: '1.5em' }}></i>
          </a>
        </div>
      );
    }

    return <Message severity="info" text={status + '...'} />;
  }

  function actionTemplate(node) {
    const { status, cancellationEffectiveDate, bondID, renewalID } = node.data;
    const { isLinked, conceptOneContractID } = status;

    const integrationStatus = consumer.integrationStatuses.find((c) => c.bondID === bondID && c.renewalID == renewalID);

    const link = <Button className="p-button-raised p-button-rounded" icon="pi pi-copy" title="Link" type="button" onClick={() => onLinkClicked(node.data)} />;

    let cancel;
    if (isLinked && cancellationEffectiveDate && !integrationStatus) {
      cancel = <Button className="p-button-raised p-button-rounded p-button-danger" icon="pi pi-times" title="Cancel" type="button" onClick={() => onCancelClicked(node.data)} />;
    }

    return (
      <div className="p-grid" data-contract={conceptOneContractID} data-bond-id={bondID}>
        <div className="p-col-4">{link}</div>
        <div className="p-col-4">{cancel}</div>
        <div className="p-col-4"></div>
      </div>
    );
  }

  function getPreviousContract(bond, renewals, renewal) {
    if (bond.expirationDate === renewal.effectiveDate) {
      return {
        contractID: bond.contractID,
        term: `${moment(bond.effectiveDate).format('l')} - ${moment(bond.expirationDate).format('l')}`,
      };
    }

    const contract = renewals.find((r) => r.expirationDate === renewal.effectiveDate);
    if (contract) {
      return {
        contractID: contract.contractID,
        term: `${moment(contract.effectiveDate).format('l')} - ${moment(contract.expirationDate).format('l')}`,
      };
    }

    return null;
  }

  const data = bonds.map((b) => {
    const renewals = b.renewals.sort((a, b) => new Date(b.effectiveDate) - new Date(a.effectiveDate));

    let key = null;
    let currentPolicy = null;
    let children = null;

    if (renewals.length > 0) {
      const currentRenewal = renewals[0];

      key = `${b.bondID}-${currentRenewal.renewalID}`;

      currentPolicy = {
        display: `${b.bondNumber}: ${b.companyName}`,
        term: `${moment(currentRenewal.effectiveDate).format('l')} - ${moment(currentRenewal.expirationDate).format('l')}`,
        status: getContractStatus(currentRenewal.contractID),
        bondNumber: b.bondNumber,
        companyName: b.companyName,
        type: 'renewal',
        renewalID: currentRenewal.renewalID,
        bondID: b.bondID,
        previousContract: getPreviousContract(b, renewals, currentRenewal),
        termMonths: currentRenewal.term,
        id: currentRenewal.renewalID,
        cancellationEffectiveDate: currentRenewal.cancellationEffectiveDate,
        expirationDate: currentRenewal.expirationDate,
        contractID: currentRenewal.contractID,
      };

      children = renewals.slice(1, renewals.length).map((r) => {
        return {
          key: `${b.bondID}-${r.renewalID}`,
          data: {
            display: '',
            term: `${moment(r.effectiveDate).format('l')} - ${moment(r.expirationDate).format('l')}`,
            status: getContractStatus(r.contractID),
            type: 'renewal',
            bondNumber: b.bondNumber,
            companyName: b.companyName,
            renewalID: r.renewalID,
            bondID: b.bondID,
            previousContract: getPreviousContract(b, renewals, r),
            termMonths: r.term,
            id: r.renewalID,
            cancellationEffectiveDate: r.cancellationEffectiveDate,
            expirationDate: r.expirationDate,
            contractID: r.contractID,
          },
        };
      });

      children.push({
        key: `${b.bondID}`,
        data: {
          display: '',
          term: `${moment(b.effectiveDate).format('l')} - ${moment(b.expirationDate).format('l')}`,
          status: getContractStatus(b.contractID),
          type: 'bond',
          bondNumber: b.bondNumber,
          companyName: b.companyName,
          id: b.bondID,
          renewalID: null,
          bondID: b.bondID,
          previousContract: null,
          termMonths: b.term,
          cancellationEffectiveDate: b.cancellationEffectiveDate,
          expirationDate: b.expirationDate,
          contractID: b.contractID,
        },
      });
    } else {
      key = b.bondID;

      currentPolicy = {
        display: `${b.bondNumber}: ${b.companyName}`,
        term: `${moment(b.effectiveDate).format('l')} - ${moment(b.expirationDate).format('l')}`,
        status: getContractStatus(b.contractID),
        type: 'bond',
        bondNumber: b.bondNumber,
        companyName: b.companyName,
        renewalID: null,
        bondID: b.bondID,
        previousContract: null,
        termMonths: b.term,
        id: b.bondID,
        cancellationEffectiveDate: b.cancellationEffectiveDate,
        expirationDate: b.expirationDate,
        contractID: b.contractID,
      };

      children = [];
    }

    return {
      key: key,
      data: currentPolicy,
      children: children,
    };
  });

  const errorFooter = (
    <div>
      <Button type="button" label="Ignore" icon="pi pi-trash" onClick={() => onIgnoreClicked(errorInfoToView.nodeData)} />
      <Button
        type="button"
        label="Retry"
        icon="pi pi-replay"
        onClick={() => {
          const { nodeData, action } = errorInfoToView;
          action === 1 ? onLinkClicked(nodeData) : onCancelClicked(nodeData);
          viewErrorDialogClosed();
        }}
      />
      <Button type="button" label="Close" icon="pi pi-times" onClick={viewErrorDialogClosed} />
    </div>
  );

  return (
    <div>
      <h3>Contracts</h3>
      <TreeTable value={data}>
        <Column field="display" header="Bond" expander />
        <Column field="term" header="Term" style={{ textAlign: 'center', width: '15em' }} />
        <Column field="1" body={statusTemplate} header="Status" style={{ width: '15em' }} />
        <Column field="2" body={actionTemplate} header="" style={{ width: '8em' }} />
      </TreeTable>

      <ChooseDialog
        selectedContract={selectedContract}
        showChooseContractDialog={showChooseContractDialog}
        onCreateContractClicked={() => {
          setShowCreateContractDialog(true);
        }}
        onRenewContractClicked={() => {
          setShowRenewContractDialog(true);
        }}
        onChooseContractDialogClosed={onChooseDialogClosed}
        consumer={consumer}
        onLinkContract={onLinkContract}
      />

      <CreateDialog
        selectedContract={selectedContract}
        consumer={consumer}
        showCreateContractDialog={showCreateContractDialog}
        onCreateContractDialogClosed={() => setShowCreateContractDialog(false)}
        onContractCreated={onContractCreated}
      />

      <RenewDialog
        selectedContract={selectedContract}
        consumer={consumer}
        showRenewContractDialog={showRenewContractDialog}
        onRenewContractDialogClosed={() => setShowRenewContractDialog(false)}
        onContractRenewed={onContractCreated}
      />

      <CancelDialog selectedContract={selectedContract} show={showCancelDialog} onClosed={() => setShowCancelDialog(false)} onCancelSuccessClosed={onContractCancelled} />

      <IgnoreDialog
        error={errorInfoToView}
        show={showIgnoreDialog}
        onClosed={() => setShowIgnoreDialog(false)}
        onIgnored={() => {
          setShowViewErrorDialog(false);
          onErrorIgnored();
        }}
      />

      <Dialog header="ConceptOne Error" visible={showViewErrorDialog} modal={true} onHide={viewErrorDialogClosed} style={{ width: '50vw' }} footer={errorFooter}>
        <Error title={errorInfoToView?.title} message={errorInfoToView?.errorMessage} details={errorInfoToView?.errorDetails} />
      </Dialog>
    </div>
  );
}

Contracts.propTypes = {
  bonds: PropTypes.array,
  consumer: PropTypes.object,
  onContractCreated: PropTypes.func.isRequired,
  onLinkContract: PropTypes.func.isRequired,
  onContractCancelled: PropTypes.func.isRequired,
  onErrorIgnored: PropTypes.func.isRequired,
};

export default Contracts;
