import camelCaseToTitle from './camelCaseToTitle';

const jsonDiffFormatter = json => {
  const result = [];
  const snapshot = JSON.parse(json);

  Object.keys(snapshot)
    .sort()
    .forEach(key => {
      const value = snapshot[key];
      const type = Object.prototype.toString.call(value);

      if (type !== '[object Object]' && type !== '[object Array]') {
        result.push(`${camelCaseToTitle(key)}: ${value || ''}`);
      } else {
        Object.keys(value)
          .sort()
          .forEach(subKey => {
            //We're just accounting for one level deep here. May need to enhance this to be recursive in the future
            const subValue = value[subKey];
            result.push(`${camelCaseToTitle(key)} => ${camelCaseToTitle(subKey)}: ${subValue || ''}`);
          });
      }
    });

  return result.join('\n');
};

export default jsonDiffFormatter;
