/* global $ */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

CreateDialog.propTypes = {
  showCreateConsumerDialog: PropTypes.bool,
  onCreateConsumerDialogClosed: PropTypes.func,
  onConsumerCreated: PropTypes.func,
};

function CreateDialog({ showCreateConsumerDialog, onCreateConsumerDialogClosed, onConsumerCreated }) {
  const [application, setApplication] = useState();
  const [spin, setSpin] = useState(true);
  const [showYesNoButtons, setShowYesNoButtons] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorDetails, setErrorDetails] = useState();
  const [newConsumer, setNewConsumer] = useState();
  const [savingMessage, setSavingMessage] = useState('Saving...');

  async function onShow() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.get(`/bonds/api/applications/${urlParams.get('applicationid')}`);
      setApplication(response.data);
      setSpin(false);
      setShowYesNoButtons(true);
    } catch (error) {
      console.error(error);
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  async function onCreateConfirm() {
    setShowYesNoButtons(false);
    setSpin(true);

    const instance = uuidv4();

    const connection = $.hubConnection('/bonds/signalr', { useDefaultPath: false });
    const proxy = connection.createHubProxy('genericMessageHub');
    proxy.on('message', (messageInstance, message) => {
      if (messageInstance == instance) {
        setSavingMessage(message);
      }
    });
    await connection.start();

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const response = await axios.post('/bonds/api/consumers', {
        applicationID: urlParams.get('applicationid'),
        connectionId: connection.id,
        instance: instance,
      });
      console.log('create response:', response);
      setNewConsumer(response.data.consumer);
      setShowCloseButton(true);

      if (!response.data.result.wasSuccess) {
        setErrorDetails(response.data.result);
        setShowError(true);
        console.log('failure');
      } else {
        setShowSuccess(true);
        console.log('success');
      }

      setSpin(false);
    } catch (error) {
      console.error(error);
      console.log('failure');
    }
  }

  async function onCreateSuccessClosed() {
    onConsumerCreated(newConsumer);
    onCreateConsumerDialogClosed();
  }

  let footer = null;
  if (showYesNoButtons) {
    footer = (
      <div>
        <Button label="Yes" icon="pi pi-check" onClick={onCreateConfirm} type="button" />
        <Button label="No" icon="pi pi-times" onClick={onCreateConsumerDialogClosed} type="button" />
      </div>
    );
  } else if (showCloseButton) {
    footer = (
      <div>
        <Button label="Close" icon="pi pi-times" onClick={onCreateSuccessClosed} type="button" />
      </div>
    );
  }

  return (
    <Dialog
      header="Create Consumer"
      visible={showCreateConsumerDialog}
      modal={true}
      onHide={onCreateConsumerDialogClosed}
      onShow={onShow}
      style={{ width: '50vw' }}
      footer={footer}
    >
      {spin ? (
        <center>
          <ProgressSpinner />
          <br />
          {savingMessage}...
        </center>
      ) : !showSuccess && !showError ? (
        <>
          <p>Are you sure you want to create this consumer in ConceptOne?</p>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Entity Name:
            </label>
            <div className="p-col">{application.mainCompany.businessName}</div>
          </div>
          <div className="p-field p-grid top">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Address:
            </label>
            <div className="p-col">
              {application.mainCompany.addressLine1}
              <br />
              {!application.mainCompany.addressLine2 || (
                <>
                  {application.mainCompany.addressLine2}
                  <br />
                </>
              )}
              {application.mainCompany.city}, {application.mainCompany.state} {application.mainCompany.zip}
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Email:
            </label>
            <div className="p-col">{application.email}</div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Phone:
            </label>
            <div className="p-col">{formatPhoneNumber(application.phone)}</div>
          </div>
          <div className="p-field p-grid top">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Affiliate:
            </label>
            <div className="p-col">
              {application.contactFirstName} {application.contactLastName}
              <br />
              {application.mainCompany.businessName}
              <br />
              {application.email}
              <br />
              Function: Bond Claims
            </div>
          </div>
          <div className="p-field p-grid top">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              FEIN:
            </label>
            <div className="p-col">{application.mainCompany.fein}</div>
          </div>
          <div className="p-field p-grid top">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Legal Entity:
            </label>
            <div className="p-col">{application.conceptOneOwnerType}</div>
          </div>
          <div className="p-field p-grid top">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Correspond Method:
            </label>
            <div className="p-col">EMail</div>
          </div>
          <div className="p-field p-grid top">
            <label className="p-col-fixed bold" style={{ width: '175px' }}>
              Rec Terms:
            </label>
            <div className="p-col">&gt;TranEff</div>
          </div>
        </>
      ) : showSuccess ? (
        <div>
          <center>
            <i className="pi pi-check-circle" style={{ fontSize: '5em', color: 'green' }}></i>
            <br />
            Consumer created successfully!
          </center>
        </div>
      ) : (
        <div>
          <center>
            <i className="pi pi-exclamation-circle" style={{ fontSize: '5em', color: 'red' }}></i>
            <br />
            <p>
              <strong>Error when creating consumer</strong>
            </p>
            <p>Message: {errorDetails.errorMessage || 'ConceptOne error'}</p>
            <p>
              <textarea rows={10} cols={100} readOnly>
                {errorDetails.errorDetails}
              </textarea>
            </p>
          </center>
        </div>
      )}
    </Dialog>
  );
}

export default CreateDialog;
