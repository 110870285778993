import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { Button } from 'primereact/button';

import Spinner from '../../integrations/dialog/Spinner';

import 'react-image-crop/dist/ReactCrop.css';

const SignatureUpload = ({ onComplete, userID }) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ height: 90, width: 120 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [existingSignature, setExistingSignature] = useState();

  useEffect(() => {
    loadExistingImage();
  }, []);

  async function loadExistingImage() {
    try {
      const response = await fetch(`/bonds/api/reporting/signature/${userID ?? 'default'}`);
      if (response.ok) {
        const existingSignature = await response.json();
        setExistingSignature(existingSignature);
      } else {
        throw response;
      }
    } finally {
      setShowSpinner(false);
    }
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);

  const onDefaultImageLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const saveImage = () => {
    setShowSpinner(true);
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      async (blob) => {
        const formData = new FormData();
        formData.append('image', blob, 'signature.png');

        if (userID) {
          formData.append('userId', userID);
        }

        const response = await fetch(`/bonds/api/reporting/signature`, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          loadExistingImage();
          setUpImg();
          onComplete();
        } else {
          throw response;
        }
      },
      'image/png',
      1,
    );
  };

  if (showSpinner) {
    return <Spinner message="Saving" />;
  }

  return (
    <div className="p-fluid">
      {existingSignature && (
        <div className="p-field">
          <label>Existing</label>
          <div>
            <img src={`data:${existingSignature.contentType};base64,${existingSignature.signatureBase64}`} style={{ border: '1px solid' }} />
          </div>
        </div>
      )}
      <div className="p-field">
        {existingSignature && <label htmlFor="change">Change</label>}
        <div>
          <input id="change" type="file" accept="image/*" onChange={onSelectFile} />
        </div>
      </div>
      {upImg && (
        <>
          <div className="p-field">
            <label>Select the dimensions</label>
            <div>
              <ReactCrop src={upImg} onImageLoaded={onDefaultImageLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
            </div>
          </div>
          <div className="p-field">
            <label>Preview</label>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  height: 33,
                  border: '1px solid',
                }}
              />
            </div>
          </div>
          <div className="p-field">
            <Button type="button" label="Save" onClick={saveImage} style={{ width: '100px' }} />
          </div>
        </>
      )}
    </div>
  );
};

SignatureUpload.propTypes = {
  onComplete: PropTypes.func.isRequired,
  userID: PropTypes.string,
};

export default SignatureUpload;
