import { useState, useEffect } from 'react';

export default function useRefreshableFetch(url, asOf) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataAsOf, setDataAsOf] = useState();

  useEffect(() => {
    async function init() {
      if (!url || !asOf) {
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(url);
        if (response.ok) {
          const json = await response.json();
          setData(json);
          setDataAsOf(asOf);
        } else {
          throw response;
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [url, asOf]);

  return { data, error, loading, dataAsOf };
}
